// inline eot for IE9
@font-face {
  font-family: 'icons';
  src: inline-font-files("../fonts/icomoon.eot", eot);
  font-weight: normal;
  font-style: normal;
}

.ribbon {
  span {
    top: 38px !important;
  }
}

.overlay,
.exhibition-links {
  background: none !important;
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(enabled=false)" !important;
}

.slide-title {
  > h1, > h2, > p {
    background-image: url(../images/components/text-shadow.png);
    background-size: 100% 100%;
  }
  h2 {
    display: inline-block;
  }
}
.page-header-visual > .page-breadcrumb {
  > ul > li {
    background-image: url(../images/components/text-shadow.png);
    background-size: 100% 100%;
  }
}
